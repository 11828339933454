<template>
  <div>
    <article>
      <h3 class="center">收益说明</h3>
      <section>
        <p>
          1、用户可以通过完成活动页面展示的任务获得金币收益，金币每天晚上0点后自动兑换为现金，兑换比例约为20000金币:1元，兑换比例受每日广告收益影响浮动。
        </p>
        <p>
          2、用户领取的金币及已兑换的现金红包等福利将在「我的钱包」页面中展示，用户可通过在底部栏福利按钮进入「我的福利」页面，点击「金币收益」或者「现金收益」进入「我的钱包」页面查看收益明细。
        </p>
        <p>
          3、用户应当每日登陆APP或者进入活动任务页面至少一次，否则可能被平台认定为不活跃用户并暂时停止发放任务奖励，用户如连续7日不活跃或未登录本APP，视为用户放弃奖励，您的现金收益和金币收益将被自动清零。
        </p>
        <p>4、用户通过平台举办的活动获得收益或奖励的，平台依法可能需要为用户代扣代缴税款或办理纳税申报。</p>
        <p>
          5、用户不得采取任何不正当或作弊的违规手段参与活动，一经发现，平台有权限制或取消用户参与活动的资格，有权取消奖励、追讨用户已领取的奖励（或同等价值的现金）。平台有权对违规用户采取限制登录、封禁帐号、限制提现/兑换、填写邀请码等措施，并保留追究用户其他法律责任的权利。
        </p>
        <p>
          6、除本说明外，平台现行有效的的《用户协议》《个人信息保护政策》以及日常活动规则（统称为「前述协议」）同样适用。本说明及相关条款与前述协议相冲突的，以本说明为准；本说明未约定的内容，仍以前述协议为准。
        </p>
        <p>
          7、在法律法规允许的范围内，平台有权对本说明进行变动或调整，相关变动或调整将公布在页面上，并于公布时即时生效，用户继续参与活动则视为同意并接受变动或者调整后的说明。如果用户拒绝说明的变更或者调整，请放弃参与变更后的活动。
        </p>
      </section>
    </article>
  </div>
</template>

<script>
  export default {
    components: {},
    props: {},
    data() {
      return {};
    },
    computed: {},
    watch: {},
    created() {},
    mounted() {
      document.title = '收益说明';
    },
    methods: {},
  };
</script>

<style scoped>
  body {
    font: 14px/150% 'Helvetica Neue', Tahoma, Arial, PingFangSC-Regular, 'Hiragino Sans GB', 'Microsoft Yahei',
      sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #fff;
    padding-left: constant(safe-area-inset-left);
    padding-left: env(safe-area-inset-left);
    padding-right: constant(safe-area-inset-right);
    padding-right: env(safe-area-inset-right);
    padding-bottom: constant(safe-area-inset-bottom);
    padding-bottom: env(safe-area-inset-bottom);
  }

  article {
    margin: 0 4%;
  }
  h3 {
    margin: 15px 0;
    padding-top: 0;
    padding-bottom: 0;
    color: #3c3c3c;
    font-size: 15px;
  }
  h5 {
    margin: 15px 0;
    font-size: 14px;
  }
  p {
    margin: 12px 0;
    font-size: 13px;
    /* text-indent: 2em; */
  }
  .center {
    text-align: center;
  }
  .sign {
    margin: 50px 0;
    text-align: right;
  }
</style>
